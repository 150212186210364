/* eslint-disable max-len */
import SaveIcon from '@mui/icons-material/Save';
import TuneIcon from '@mui/icons-material/Tune';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { format } from 'date-fns';
import add from 'date-fns/add';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  addExternalUser,
  getAllMills,
  getFarmUsers,
  saveAllChanges,
  setUsersList,
  updateUserDate,
} from '../../../actions/farmsAccessActions';
import { AddExternalUserModal, ChangeDateModal, FarmsAccessTable, FarmsList } from '../../../components/farms-access';
import LoadScreen from '../../../components/load-screen';
import { Button, ConfirmationModal, DatePicker, SearchInput, Select } from '../../../components/shared';
import Autocomplete from '../../../components/shared/autocomplete';
import { useAlert, useLoading } from '../../../hooks';
import { farmsAccessSelector } from '../../../reducers/selectors';
import { containerPadding } from '../../../styles/theme/shared';

const FarmsAccessPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const adminFarms = useSelector(farmsAccessSelector);
  const { showAlert } = useAlert();

  const [currentMill, setCurrentMill] = useState();
  const [changes, setChanges] = useState([]);
  const [addExtUserModalOpen, setAddExtUserModalOpen] = useState(false);
  const [changeDateModalOpen, setChangeDateModalOpen] = useState(false);
  const [selectAllModalOpen, setSelectAllModalOpen] = useState(false);
  const [defaultDate, setDefaultDate] = useState(add(new Date(), { years: 1 }));
  const [currentUser, setCurrentUser] = useState();

  const [selectedFarms, setSelectedFarms] = useState([]);
  const [search, setSearch] = useState('');

  const { loading, startLoading, stopLoading } = useLoading(true);

  const { millsList, usersList, farmsList: farms } = adminFarms;

  const farmsList = useMemo(() => {
    let list = [...farms];

    if (selectedFarms?.length) {
      list = list.filter((item) => selectedFarms?.map((f) => f.farm_id)?.includes(item.farm_id));
    }

    if (search?.length) {
      list = list.filter((item) => item.farm_name?.toLowerCase()?.includes(search?.toLowerCase()));
    }

    return list;
  }, [farms, search, selectedFarms]);

  const onLoad = async () => {
    try {
      startLoading();
      await dispatch(getAllMills());
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const millsOptions = useMemo(
    () =>
      millsList?.map((mill) => ({
        ...mill,
        value: mill?.mill_id,
        label: mill?.mill_name,
      })),
    [millsList]
  );

  useEffect(() => {
    onLoad().catch(console.error);

    return () => {};
  }, []);

  const formatDataToState = (data) => {
    const mappedFarms = [];
    data?.farms?.forEach((farm) => {
      data?.users?.forEach((user) => {
        mappedFarms.push({
          farm_id: farm.farm_id,
          user_id: user.user_id,
          grant_access: !!farm?.permitted_users?.find((u) => u.email === user.email),
          expiration_date: farm?.permitted_users?.find((u) => u.email === user.email)?.expiration_date,
        });
      });
    });
    setChanges(mappedFarms);
  };

  const onSelectChange = async (e) => {
    try {
      startLoading();
      const mill = millsList.find((el) => el.mill_id === e.target.value);
      setCurrentMill(mill);
      const data = await dispatch(
        getFarmUsers({
          Mill_ID: mill?.mill_id,
        })
      );
      formatDataToState(data);
    } catch (err) {
      // console.log('error', err);
    } finally {
      stopLoading();
    }
  };

  const handleSelectAll = () => {
    setSelectAllModalOpen(true);
  };

  const handleChange = async (checked, row, type, user) => {
    const reqObj = [];
    let tempChanges = [];
    switch (type) {
      case 'farm':
        usersList?.forEach((k) => {
          reqObj?.push({
            farm_id: row.farm_id,
            user_id: k.user_id,
            grant_access: checked,
            expiration_date: row?.permitted_users?.find((u) => u.email === k.email)?.expiration_date || defaultDate,
          });
        });
        tempChanges = changes.filter((tmpFarm) => tmpFarm?.farm_id !== row?.farm_id);
        break;
      case 'user':
        farmsList?.forEach((farm) => {
          reqObj?.push({
            farm_id: farm.farm_id,
            user_id: user.user_id,
            grant_access: checked,
            expiration_date: farm?.permitted_users?.find((u) => u.email === user.email)?.expiration_date || defaultDate,
          });
        });
        tempChanges = changes.filter((tmpFarm) => tmpFarm?.user_id !== user?.user_id);
        break;
      case 'date':
        reqObj?.push({
          farm_id: row.farm_id,
          user_id: user.user_id,
          grant_access: checked,
          expiration_date: row?.permitted_users?.find((u) => u.email === user.email)?.expiration_date || defaultDate,
        });
        tempChanges = changes.filter(
          (tmpFarm) => !(tmpFarm?.farm_id === row?.farm_id && tmpFarm?.user_id === user?.user_id)
        );
        break;
      case 'all':
      default:
        break;
    }

    setChanges([...tempChanges, ...reqObj]);
  };

  const handleAddUser = async (date, email, farms) => {
    try {
      startLoading();
      const userObj = {
        farm_ids: farms?.map((f) => f.farm_id),
        email: email?.trim(),
        expiration_date: format(date, 'yyyy-MM-dd'),
      };
      const res = await dispatch(addExternalUser(userObj));
      if (res) {
        const user = {
          email,
          is_external_user: true,
          user_id: res?.data?.user_id,
        };
        dispatch(setUsersList([...usersList, user]));
        const newChanges = farms.map((f) => ({
          farm_id: f.farm_id,
          user_id: user.user_id,
          grant_access: !!farms.find((el) => el.farm_id === f?.farm_id),
          expiration_date: farms.find((el) => el.farm_id === f?.farm_id) ? userObj?.expiration_date : undefined,
        }));
        setChanges([...changes, ...newChanges]);
      } else {
        showAlert('error', t('farmsAccess.error'));
      }
    } catch (e) {
      console.error(e);
    } finally {
      stopLoading();
    }
  };

  const handleChangeDate = (user, row, date) => {
    setCurrentUser({
      ...user,
      ...row,
      date,
    });
    setChangeDateModalOpen(true);
  };

  const handleSelectAllChanges = () => {
    const intUsers = usersList.filter((user) => !user?.is_external_user)?.map((u) => u?.user_id);
    const newChanges = changes.map((ch) => ({
      ...ch,
      expiration_date: ch?.expiration_date || format(defaultDate, 'yyyy-MM-dd'),
      grant_access: intUsers.includes(ch?.user_id) ? true : ch?.grant_access,
    }));
    setChanges(newChanges);
    setSelectAllModalOpen(false);
  };

  const handleChangeDateSubmit = async (newDate, user) => {
    try {
      startLoading();
      const reqObj = {
        farm_id: user.farm_id,
        user_id: user.user_id,
        expiration_date: format(newDate, 'yyyy-MM-dd'),
      };
      await dispatch(updateUserDate(reqObj));
      await dispatch(
        getFarmUsers({
          Mill_ID: currentMill?.mill_id,
        })
      );
      const newObjChanges = {
        ...reqObj,
        grant_access: true,
      };
      const tempChanges = changes.filter(
        (tmpFarm) => !(tmpFarm?.farm_id === user?.farm_id && tmpFarm?.user_id === user?.user_id)
      );
      setChanges([...tempChanges, newObjChanges]);
    } catch (e) {
      console.error(e);
    } finally {
      stopLoading();
    }
  };

  const handleChangeAllFarms = (user, isEnable) => {
    const reqObj = [];
    let tempChanges = [];
    farmsList?.forEach((farm) => {
      reqObj?.push({
        farm_id: farm.farm_id,
        user_id: user.user_id,
        grant_access: isEnable,
        expiration_date: farm?.permitted_users?.find((u) => u.email === user.email)?.expiration_date || defaultDate,
      });
    });
    tempChanges = changes.filter((tmpFarm) => tmpFarm?.user_id !== user?.user_id);

    setChanges([...tempChanges, ...reqObj]);
    setChangeDateModalOpen(false);
  };

  const handleSaveChanges = async () => {
    try {
      startLoading();
      const ch = changes?.filter((c) => c?.expiration_date);
      await dispatch(
        saveAllChanges(
          ch?.map((q) => ({
            ...q,
            expiration_date:
              typeof q.expiration_date === 'object' ? format(q.expiration_date, 'yyyy-MM-dd') : q.expiration_date,
          }))
        )
      );
    } catch (e) {
      console.error(e);
    } finally {
      stopLoading();
    }
  };

  const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <Container maxWidth={false} sx={{ p: containerPadding }}>
      {loading && <LoadScreen />}
      <h2>{t('farmsAccess.title')}</h2>
      <Box
        component='div'
        sx={{
          mb: '16px',
          display: 'flex',
          justifyContent: 'space-between',
          gap: '16px',
          flexDirection: mobileView ? 'column' : 'row',
        }}
      >
        <Select
          id='template-select'
          label={t('farmsAccess.select')}
          onChange={onSelectChange}
          options={millsOptions}
          sx={{
            flex: '0.5 !important',
            minWidth: '50%',
          }}
          value={currentMill?.mill_id || 0}
        />
        {currentMill && (
          <Box
            component='div'
            sx={{ display: 'flex', flexDirection: mobileView ? 'column' : 'row', alignItems: 'center' }}
          >
            <DatePicker
              disablePast
              label={t('farmsAccess.validUntil')}
              onChange={setDefaultDate}
              openTo={'year'}
              value={defaultDate}
              views={['year', 'month', 'day']}
            />
            <Button
              onClick={() => setAddExtUserModalOpen(true)}
              sx={{ margin: mobileView ? '16px 0' : '0 0 0 16px', minWidth: '180px' }}
            >
              {t('farmsAccess.addExtUser')}
            </Button>
            {mobileView && (
              <Button onClick={() => handleSelectAll()} outlined>
                {t('farmsAccess.grantFullAccess')}
              </Button>
            )}
          </Box>
        )}
      </Box>
      {currentMill && (
        <Box
          sx={{
            width: { sm: '50%' },
            mt: 2,
            mb: 2,
            p: 1,
            background: '#ffffff',
            borderRadius: 1,
            border: (theme) => `1px solid ${theme?.palette?.primary?.grey}`,
            minWidth: {
              md: 510,
            },
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TuneIcon color='secondary' />
              <Typography sx={{ ml: 1 }}>Filters</Typography>
            </Box>
            <Box sx={{ flex: 1, ml: 2 }}>
              <SearchInput
                handleClear={() => setSearch('')}
                onChange={(e) => setSearch(e.target.value)}
                sx={{ width: '100%' }}
                value={search}
                size='small'
                placeholder='Search by farm name'
              />
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Autocomplete
              getOptionLabel={(option) => option.farm_name}
              inputLabel={t('farms.label')}
              inputPlaceholder={t('farms.placeholder')}
              isOptionEqualToValue={(option, value) => option.farm_id === value.farm_id}
              labelKey='farm_name'
              noOptionsText={t('farms.noOptions')}
              onChange={(e, values) => setSelectedFarms(values)}
              options={farms}
              value={selectedFarms}
              valueKey='farm_id'
              size='small'
              mb={0}
            />
          </Box>
        </Box>
      )}
      {mobileView ? (
        <div>
          {currentMill && (
            <FarmsList
              changes={changes}
              farms={farmsList}
              handleChange={handleChange}
              handleChangeDate={handleChangeDate}
              handleSelectAll={handleSelectAll}
              users={usersList}
            />
          )}
        </div>
      ) : (
        <div>
          {currentMill && (
            <FarmsAccessTable
              changes={changes}
              farms={farmsList}
              handleChange={handleChange}
              handleChangeDate={handleChangeDate}
              handleSelectAll={handleSelectAll}
              users={usersList}
            />
          )}
        </div>
      )}
      {currentMill && (
        <Fab
          aria-label='add'
          color='primary'
          onClick={() => handleSaveChanges()}
          sx={{
            margin: 0,
            top: 'auto',
            right: 20,
            bottom: 20,
            left: 'auto',
            fontWeight: 'bold',
            position: 'fixed',
          }}
          variant='extended'
        >
          <SaveIcon sx={{ mr: 1 }} />
          {t('submit')}
        </Fab>
      )}
      <AddExternalUserModal
        addUser={handleAddUser}
        defaultDate={defaultDate}
        farmsList={farmsList}
        onClose={() => setAddExtUserModalOpen(false)}
        open={addExtUserModalOpen}
      />
      <ChangeDateModal
        currentUser={currentUser}
        handleChangeAllFarms={handleChangeAllFarms}
        handleSubmit={handleChangeDateSubmit}
        mobileView={mobileView}
        onClose={() => setChangeDateModalOpen(false)}
        open={changeDateModalOpen}
      />
      <ConfirmationModal
        onClose={() => setSelectAllModalOpen(false)}
        onSubmit={handleSelectAllChanges}
        open={selectAllModalOpen}
        text={t('farmsAccess.grantFullAccessText')}
      />
    </Container>
  );
};

export default FarmsAccessPage;
